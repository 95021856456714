<template>
    <div>
        <el-row v-has-permi="['batch:execLog:query']" class="sub-search">
            <el-form ref="form" :model="form" :inline="true" style="text-align: left;">
                <el-form-item>
                    <el-select v-model="form.jobId" placeholder="" style="width: 200px">
                        <el-option v-for="(item,index) in options" :key="'m_'+index"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="date-item">
                    <el-date-picker
                            v-model="createDate"
                            type="daterange"
                            style="width: 270px"
                            align="center"
                            range-separator="-"
                            start-placeholder="開始日付"
                            end-placeholder="終了日付"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>

                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="searchBatch">検索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-refresh-right" @click="reForm">クリア</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row class="content">
            <el-table v-has-permi="['batch:execLog:list']" :data="tableData" style="width: 100%;"
                      :max-height="tableHeight">
                <el-table-column align="center" prop="jobName" label="タスク名称" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="startTime" label="実行時間" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="autoStart" label="実行方法" width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                          <span v-if="scope.row.autoStart == '0'">
                            <span style="font-family:'FontAwesome5Pro-Solid', 'Font Awesome 5 Pro Solid', 'Font Awesome 5 Pro',
                            sans-serif;font-weight:900;color:#66BE96;" class="el-icon-data-line"></span>
                            自動</span>
                        <span v-if="scope.row.autoStart == '1'">手動</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="supplierFunctionSyncFailedCount" label="失敗バッチ数" width="120">
                </el-table-column>
                <el-table-column align="center" prop="failureDataCount" label="失敗データ件数" width="120">
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button v-has-permi="['batch:execLog:detail']" class="small-btn" type="primary"
                                   size="small"
                                   @click="showDetail1(scope.row)">データ明細
                        </el-button>
                        <el-button v-has-permi="['batch:execLog:detail']" class="small-btn" type="primary"
                                   size="small"
                                   @click="showDetail2(scope.row)">バッチ明細
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="pagina-box" background layout="prev, pager, next"
                           :total="total" :current-page="pageNo"
                           :page-size="pagesize"
                           @current-change="onPage"
                           @size-change="onSizeChange">
            </el-pagination>
        </el-row>
        <el-dialog :visible.sync="dialogVisible" width="30%"
                   :close-on-click-modal="false" :show-close="false">
            <div slot="title" class="dialog-title-box">
                <span>{{ title }} |</span>
                <span> 商品在庫情報</span>
            </div>
            <batch-record-error-detail style="max-height: 400px;"></batch-record-error-detail>
            <span slot="footer" class="dialog-footer" style="margin-top: 10px;">
                <el-button @click="dialogVisible = false" icon="el-icon-back">戻る</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "exec-log-detail1",
        data() {
            return {
                title: '',
                dialogVisible: false,
                tableData: [],
                //日期范围
                createDate: [],
                options: [
                    {
                        label: '全てのタスク',
                        value: ''
                    }
                ],
                tableHeight: 0,
                total: 0,
                pageNo: 1,
                pagesize: 10,
                itle: '',
                form: {
                    jobId: '',
                    params: {
                        beginTime: '',
                        endTime: ''
                    }
                },

            }
        },
        mounted() {
            this.masterList();
            this.getTableMaxHeight();
            this.getList();
            let _this = this;
            window.onresize = function () {//テーブルを高度に適応させるための方法
                _this.getTableMaxHeight();//容器の現在の高さを取得し、テーブルの最大高さをリセットする
            }
        },
        methods: {
            async masterList() {
                let res = await this.$http.batchRecord.masterList();
                if (res.code === 200) {
                    res.rows.forEach(item => {
                        this.options.push({
                            label: item.jobName,
                            value: item.jobId
                        })
                    })

                }
            },
            showDetail1( row) {
                this.$emit('btnClick1', row)
            },
            showDetail2( row) {
                this.$emit('btnClick2', row)
            },
            /**
             * 取得リスト
             */
            async getList() {
                this.form.pageNum = this.pageNo;
                this.form.pageSize = this.pagesize;
                if (this.createDate) {
                    this.form.params.beginTime = this.createDate[0]
                    this.form.params.endTime = this.createDate[1]
                } else {
                    this.form.params.beginTime = ''
                    this.form.params.endTime = ''
                }
                let res = await this.$http.batchRecord.list(this.form);
                if (res.code === 200) {
                    this.total = res.total;
                    this.tableData = res.rows;
                }
            },
            /**
             * 検索
             */
            searchBatch() {
                this.pageNo = 1;
                this.getList();
            },
            /**
             * クエリ条件をリセットする
             */
            reForm() {
                this.createDate = ""
                this.form = {
                    status: '',
                    params: {
                        beginTime: '',
                        endTime: ''
                    }
                }
            },
            /**
             * 検索詳細リスト
             * */
            searchDetailList() {
                this.searchBatch();
            },
            onPage(num) {
                this.pageNo = num;
                this.getList()
            },
            // ページ毎の表示数変更イベント
            onSizeChange(size) {
                this.pageNo = 1;
                this.pagesize = size;
                this.getList()
            },

            add() {
                this.types = 'add';
                this.dialogVisible = true;
            },
            edit() {
                this.types = 'edit';
                this.dialogVisible = true;
            },
            editInfo() {
                this.types = 'editInfo';
                this.dialogVisible = true;
            },
            //容器の現在の高さを取得し、テーブルの最大高さをリセットする
            getTableMaxHeight() {
                this.$nextTick(function () {
                    if (!document.querySelector(".content")) {
                        return;
                    }
                    let box = document.querySelector(".content").attributes
                    let box_clientHeight = box[0].ownerElement.clientHeight;
                    this.tableHeight = box_clientHeight - 100;
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
  .sub-search {
    height: 75px;
    background: white;
    box-shadow: 0px 0px 15px rgba(170, 170, 170, 35%);
    padding: 18px 30px 0 30px;
    > > .el-input__prefix {
      color: #AAAAAA;;
    }

    /deep/ .el-form-item__content {
      margin-left: 0px !important;
    }

    .input-item {
      /deep/ .el-input__suffix {
        left: 5px;
        right: auto;
      }

      /deep/ .el-input--suffix .el-input__inner {
        padding-right: 10px;
        padding-left: 30px;
      }
    }

    .date-item {
      /deep/ .el-input__prefix {
        left: 0px;
        -webkit-transition: all .3s;
        transition: all .3s;
        width: 40px;
        background: rgba(242, 242, 242, 1);
      }

      /deep/ .el-input__inner {
        padding-left: 45px;
      }
    }
  }
  .content {
    box-shadow: 0px 0px 15px rgba(170, 170, 170, 35%);
    padding: 30px 30px;
    height: calc(100vh - 320px);
    background: rgba(255, 255, 255, 1);
    margin-top: 20px;
    .pagina-box {
      margin-top: 20px;
      text-align: right;
    }
  }

  .small-btn {
    color: white;
    height: 24px !important;
    padding: 9px 15px !important;
    line-height: 1px;
  }
</style>